<template>
  <div class="right">
    <span class="spen">退换货</span>
    <div class="menu">
      <div class="daohang">
        <ul class="ul">
          <router-link :to="{ name: 'Tuihuo' }" tag="li">退换货</router-link>
          <router-link :to="{ name: 'Jilu' }" tag="li">退换货记录</router-link>
          <router-link :to="{ name: 'Mingxi' }" tag="li">退款明细</router-link>
        </ul>
      </div>
    </div>
    <div class="con">
      <div class="biao">
        <div class="fuwu">服务单信息</div>
        <div class="tuikuan">退款明细</div>
        <div class="chae">差额原因</div>
        <div class="xinxi">退款信息</div>
        <div class="caozuo">{{ $t('label.Controls') }}</div>
      </div>
      <div v-for="(item, index) in list" :key="index">
        <div class="top">
          <div class="biaoti">{{ item.applyTime }}</div>
          <div class="biaoti mar">
            {{ $t('label.Order_number') }}：<span class="danhao">{{ item.orderNo }}</span>
          </div>
          <div class="biaoti mar dianpu" @click="dianpu(item)">
            {{ $t('common.shop') }}：{{ item.storeName }}
          </div>
          <div class="biaoti mar kefu">
            <span>客服</span><img src="../../../../assets/order/kefu.png" />
          </div>
        </div>
        <div class="bottom">
          <div class="pic">
            <Uimage :src="item.defaultImg" alt="" />
          </div>
          <div class="title1">
            <div class="title">
              <div class="miaoshu">
                {{ item.spuName }}
              </div>
              <div class="shuliang">X1</div>
            </div>
          </div>
          <div class="tousu">
            <div class="zhifu">
              微信支付(预计1-3个工作日到账)
            </div>
            <div class="zonge">
              退款总额：<span class="spen1">￥{{ item.refundAmount }}</span>
            </div>
          </div>
          <div class="price">
            <div class="jiage">无</div>
          </div>
          <div class="zhuangtai">
            <div class="xiangqing">
              {{ item.status == '0' ? $t('common.Being_refunded') : '退款完成' }}
            </div>
          </div>
          <div class="pay">
            <div class="liji" @click="details">查看</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { post, get } from '@/utils/request';

export default {
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.getlist();
  },
  methods: {
    //店铺
    dianpu(e) {
      // this.$router.push({
      //   name: 'store_index',
      //   params: {
      //     storeId: e.storeId,
      //   },
      // });
       const { href } = this.$router.resolve({
        name: 'store_index',
        params: {
          storeId: e.storeId,
        },
      });
      window.open(href, '_blank');
    },
    getlist() {
      get('api/orderreturn/getorderreturnlist').then((res) => {
        this.list = res.data.data;
      });
    },
    details() {
      this.$router.push({
        name: 'tuihuo-details',
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.tishi {
  width: 90%;
  text-align: center;
  margin-top: 150px;
  font-size: 24px;
  color: rgb(139, 135, 135);
}
.fenye {
  margin-top: 50px;
  margin-bottom: 20px;
}
.right {
  width: 1006px;
  padding-top: 34px;
  padding-left: 27px;
  background: #fff;
  .spen {
    font-size: 26px;
    font-weight: 600;
    color: #616161;
  }
  .menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // .qiehuan {
    //   height: 80px;
    //   display: flex;
    //   align-items: center;
    //   cursor: default;
    //   .daohang {
    //     font-size: 15px;
    //     color: #272727;
    //     margin-right: 25px;
    //     cursor: pointer;
    //   }
    //   .bian {
    //     color: #1a4fff;
    //   }
    // }
    .daohang {
      font-size: 15px;
      color: #272727;
      margin-right: 25px;
      height: 30px;

      .ul {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0;
        width: 250px;
        height: 32px;
        line-height: 40px;
        li {
          list-style: none;
          cursor: pointer;
          &.router-link-exact-active.router-link-active {
            color: #3661fe;
          }
        }
      }
    }
    // .search {
    //   margin-right: 150px;
    //   display: flex;
    //   input {
    //     border: 0;
    //     outline: none;
    //     border: 1px solid #a7a7a7;
    //     height: 37px;
    //     padding-left: 10px;
    //   }
    //   .fangdajing {
    //     width: 45px;
    //     height: 40px;
    //     border: 1px solid #a7a7a7;
    //     border-left: none;
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //   }
    // }
  }
  .con {
    margin-top: 40px;
  }
  .biao {
    width: 100%;
    height: 42px;
    line-height: 42px;
    background: #f5f5f5;
    display: flex;
    font-size: 18px;
    color: #888;
    .fuwu {
      margin-left: 20px;
      width: 390px;
    }
    .tuikuan {
      width: 230px;
    }
    .chae {
      width: 130px;
    }
    .xinxi {
      width: 140px;
    }
    .caozuo {
      width: 40px;
    }
  }
  .top {
    width: 100%;
    height: 42px;
    line-height: 42px;
    background: #f5f5f5;
    display: flex;
    .biaoti {
      font-size: 16px;
      color: #888;
      margin-left: 20px;
      .danhao {
        color: #666;
        font-weight: 800;
      }
      img {
        width: 23px;
        height: 23px;
        margin-left: 10px;
      }
    }
    .dianpu {
      cursor: pointer;
    }
    .mar {
      margin-left: 40px;
    }
    .kefu {
      display: flex;
      align-items: center;
    }
  }
  .bottom {
    width: 100%;
    height: 137px;
    border-bottom: 1px solid #f5f5f5;
    display: flex;
    .pic {
      width: 180px;
      height: 136px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .title1 {
      height: 100%;
      border-right: 1px solid #eaeaea;
      .title {
        width: 240px;
        padding-top: 34px;
        display: flex;
        .miaoshu {
          color: #333;
          width: 190px;
          height: 50px;
          margin-left: 10px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }
        .shuliang {
          margin-left: 10px;
        }
      }
    }
    .tousu {
      width: 250px;
      border-right: 1px solid #eaeaea;
      text-align: center;
      font-size: 14px;
      color: #333;
      padding: 34px 5px 0 5px;
      .spen1 {
        color: #dc7413;
      }
    }
    .price {
      width: 140px;
      border-right: 1px solid #eaeaea;
      padding-top: 34px;
      .jiage {
        font-size: 14px;
        color: #333;
        text-align: center;
      }
    }
    .zhuangtai {
      width: 140px;
      padding-top: 34px;
      border-right: 1px solid #eaeaea;
      text-align: center;
      color: #333;
      .xiangqing {
        margin-top: 5px;
        color: #dc7413;
      }
      .wuliu {
        color: #dc7413;
        margin-top: 5px;
      }
    }
    .pay {
      width: 136px;
      padding-top: 34px;
      border-right: 1px solid #eaeaea;
      font-size: 14px;
      color: #333;
      text-align: center;
      .liji {
        color: #333;
        cursor: pointer;
      }
    }
  }
}
</style>
